<template>
  <v-container fluid :class="{ 'call-center-margin': hasCallCenter }">
    <AvailabilityCalendar />
  </v-container>
</template>

<script>
import AvailabilityCalendar from '@/components/AvailabilityCalendar.vue'

export default {
  components: {
    AvailabilityCalendar,
  },
}
</script>
